import { gql } from '@apollo/client';

/**
 * scema dynamic category
 * @param variables Object {id: number, productSize: Number}
 * @returns grapql query
 */

export const getCategory = (
    variables = {
        productSize: 10,
    },
) => gql`
    {
        categoryList(filters: { ids: { eq: "${variables.id}" } }) {
          id
          name
          description
          url_path
          image
          image_path
          breadcrumbs {
            category_id
            category_name
            category_url_path
          }
          children {
            id
            name
            image
            url_path
          }
          cms_block {
            content
            identifier
            title
          } 
          display_mode
          meta_description
          meta_keywords
          meta_title
          seo_canonical
        }
    }
    `;

/**
 * scema dynamic get attribute filter
 * @param category_id number
 * @returns grapql query
 */

export const getFilter = (catID) => gql`
    {
        getFilterAttributeOptions (catid:${catID}) {
            code
            data {
                field
                id
                label
                maxprice
                minprice
                value {
                    label
                    value
                }
            }
            message
            status
        }
    }
`;

export const getTopSellerSchema = gql`
query getTopSellerCategoryProducts($categoryId: Int!, $selectedStore: Int!) {
  getTopSellerCategoryProducts(
    selectedStore: $selectedStore
    pageSize: 10
    currentPage: 1
    categoryId: $categoryId
  ) {
    product {
      id
      weltpixel_labels {
        categoryLabel {
          css
          customer_group
          image
          page_position
          position
          priority
          text
          text_padding
          text_bg_color
          text_font_size
          text_font_color
          __typename
        }
        productLabel {
          css
          customer_group
          image
          page_position
          position
          priority
          text
          text_padding
          text_bg_color
          text_font_size
          text_font_color
          __typename
        }
        __typename
      }
      name
      sku
      brand
      url_key
      small_image {
        url
        label
        url_original
        __typename
      }
      product_sold
      description {
        html
        __typename
      }
      short_description {
        html
        __typename
      }
      more_info {
        label
        value
        __typename
      }
      price_range {
        minimum_price {
          discount {
            amount_off
            percent_off
            __typename
          }
          final_price {
            currency
            value
            __typename
          }
          fixed_product_taxes {
            amount {
              currency
              value
              __typename
            }
            label
            __typename
          }
          regular_price {
            currency
            value
            __typename
          }
          __typename
        }
        maximum_price {
          discount {
            amount_off
            percent_off
            __typename
          }
          final_price {
            currency
            value
            __typename
          }
          fixed_product_taxes {
            amount {
              currency
              value
              __typename
            }
            label
            __typename
          }
          regular_price {
            currency
            value
            __typename
          }
          __typename
        }
        __typename
      }
      __typename
    }
    vendor_product_data {
      vendor_id
      product_id
      vendor_cost
      vendor_stock
      vendor_special_price
      flash_sale_price
      flash_sale_from
      flash_sale_percent
      flash_sale_discount_amount
      flash_sale_to
      show_countdown
      special_price
      preorder
      preorder_time
      preorder_time_config
      __typename
    }
    __typename
  }
}`;
